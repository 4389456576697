<template>
    <div class="video"
         :class="[isShowControls && !isFullScreen ? 'video_bg' : '', 'video_' + $store.getters.getTypeView]"
         ref="wrapper" @click="clickToVideo" @mouseover="showControls" @mouseleave="hideControls">
        <div class="video__video" ref="videoWrap" :style="getWrapperStyles">
            <video ref="video"
                   v-if="isFullScreen"
                   class="video__item"
                   :class="[videoSet.current.z > 1 ? 'video__item_grab' : '',
                   countTap === 2 ? 'video__item_translate' : '']"
                   :style="getVideoStyles"
                   v-hammer:tap="changeTap"
                   v-hammer:pan="changePan"
                   v-hammer:panend="endPan"
                   v-hammer:pinchstart="startPinch"
                   v-hammer:pinchend="endPinch"
                   v-hammer:pinch="changePinch"
                   playsinline autoplay muted></video>
            <video ref="video"
                   class="video__item"
                   @click="fullScreenToGrid"
                   v-else
                   :class="[videoSet.current.z > 1 ? 'video__item_grab' : '',
                   countTap === 2 ? 'video__item_translate' : '']"
                   :style="getVideoStyles"
                   playsinline autoplay muted></video>
            <video-interface
                    v-if="isFullScreen"
                    :video="video"
                    :stop="stop"
                    :hasAudio="hasAudio"
                    :zoom="videoSet.current.z"
                    @changePlay="changePlay"
                    @changeZoom="changeZoom"
                    @exitScreen="exitScreen"
            ></video-interface>
        </div>
        <div class="video__loader" v-if="isLoading">
            <video-loader></video-loader>
        </div>
        <h2 class="video__title"
            v-if="$store.getters.getIsPay && isShowControls">
            {{video.name}}</h2>
        <div class="video__text" v-if="!$store.getters.getIsPay">
            Продлите подписку в управлении тарифом
        </div>
        <div class="video__info" v-if="($store.getters.getTypeView === 'column' && $windowWidth > 768 && isShowControls) || isShowControls">
            <div v-if="hasAudio !== null" class="video__audio">
                <div v-if="hasAudio" class="video__sound">
                    <a class="icon icon-mute-1 volume-off--image" @click="toggleMute" v-if="isMuted"
                       :class="{'show' : isShowControls}"></a>
                    <a class="icon icon-loud volume-on--image" @click="toggleMute" v-else
                       :class="{'show' : isShowControls}"></a>
                </div>
                <div v-else class="video__no-sound">
                    <div class="no-audio">Без звука</div>
                </div>
            </div>
            <div class="video__archive"
                 v-if="$store.getters.getIsPay && isShowArchive"
                 @click="onArchive"
                 :class="{'show' : isShowControls && video.showAddingControls && video.isRecorder,'right' : isShowShare}"
            >
                <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="video__svg">
                    <path opacity="0.897879" fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.9638 17.1H6.17304C3.30417 17.1 0.970215 14.8702 0.970215 12.1289C0.970215 10.225 2.26792 8.24867 4.05641 7.42963L4.32842 7.30542V7.01667C4.32842 6.95846 4.33226 6.89934 4.33562 6.83976L4.33994 6.76642L4.33418 6.67017C4.3313 6.63305 4.32842 6.59592 4.32842 6.55834C4.32842 4.78963 5.8353 3.35001 7.68663 3.35001C7.83487 3.35001 7.98119 3.36238 8.12656 3.38071C8.16398 3.3853 8.2014 3.39126 8.23834 3.39721C8.36643 3.41738 8.4926 3.44396 8.61685 3.47834C8.63364 3.48292 8.65092 3.48613 8.66771 3.49071C8.80443 3.53059 8.9378 3.58101 9.06829 3.63784C9.10235 3.65251 9.13546 3.66855 9.16904 3.68413C9.28418 3.73867 9.39692 3.79826 9.50582 3.86563C10.4303 4.43763 11.0448 5.42992 11.0448 6.55834C11.0448 6.8118 11.2593 7.01667 11.5246 7.01667C11.7899 7.01667 12.0043 6.8118 12.0043 6.55834C12.0043 5.29609 11.4066 4.1663 10.4691 3.40913C11.8446 1.69038 14.1094 0.600006 16.3628 0.600006C20.0784 0.600006 23.1641 3.41188 23.486 6.96534C22.9957 6.93234 22.2617 6.91538 21.5272 7.02217C21.2653 7.06021 21.0854 7.29396 21.1252 7.54421C21.1612 7.77109 21.3656 7.9338 21.5987 7.9338C21.6227 7.9338 21.6472 7.93196 21.6712 7.9283C22.7396 7.77384 23.853 7.91867 23.9183 7.92738C26.152 8.33301 27.8359 10.2988 27.8359 12.5038C27.8359 15.038 25.6775 17.1 23.025 17.1H16.8018H12.9638Z"
                          fill="white"/>
                </svg>
            </div>
            <div class="video__fullscreen" @click="goToFullSize">
                <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="video__svg">
                    <path d="M17.6567 1.84998H24.3732V8.44998" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                    <path d="M8.70142 1.84998H1.98502V8.44998" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                    <path d="M17.6567 20.5499H24.3732V13.9499" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                    <path d="M8.70142 20.5499H1.98502V13.9499" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="video__share" @click="onShare" v-if="isShowShare && $store.getters.getIsPay">
                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="video__svg">
                    <g opacity="0.897879">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M21.1325 6.80661L15.0601 0.838211C14.9098 0.69053 14.732 0.616669 14.5264 0.616669C14.3208 0.616669 14.1429 0.69053 13.9927 0.838211C13.8425 0.985973 13.7672 1.16085 13.7672 1.36287V4.34707H11.1106C5.47292 4.34707 2.01394 5.91306 0.732873 9.04494C0.313939 10.0862 0.104492 11.3803 0.104492 12.9268C0.104492 14.2168 0.606591 15.9692 1.6107 18.1841C1.63438 18.2386 1.67576 18.3317 1.73516 18.4637C1.79452 18.5957 1.84782 18.7123 1.89526 18.8134C1.94286 18.9143 1.99429 18.9998 2.04958 19.0697C2.14437 19.2019 2.25512 19.2681 2.38165 19.2681C2.50025 19.2681 2.59318 19.2293 2.66043 19.1516C2.72752 19.0739 2.76112 18.9766 2.76112 18.8604C2.76112 18.7902 2.75124 18.6874 2.73142 18.5513C2.71165 18.4152 2.70172 18.3241 2.70172 18.2774C2.66213 17.7492 2.64236 17.2709 2.64236 16.8437C2.64236 16.0589 2.71165 15.3555 2.8499 14.7339C2.98835 14.1121 3.18006 13.574 3.42523 13.1193C3.67036 12.6645 3.98652 12.2723 4.37405 11.942C4.76142 11.6117 5.17844 11.3417 5.62517 11.1319C6.07197 10.922 6.59775 10.7568 7.20262 10.6364C7.80745 10.516 8.41622 10.4324 9.02907 10.3857C9.64191 10.339 10.3357 10.3158 11.1106 10.3158H13.7672V13.3001C13.7672 13.5022 13.8423 13.6771 13.9924 13.8247C14.1428 13.9722 14.3206 14.0461 14.5261 14.0461C14.7316 14.0461 14.9096 13.9722 15.0601 13.8247L21.1324 7.85606C21.2826 7.70838 21.3577 7.53359 21.3577 7.33152C21.3576 7.1295 21.2826 6.95458 21.1325 6.80661Z"
                              fill="white"/>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Hls from 'hls.js'
    import VideoLoader from "../loaders/VideoLoader";
    import VideoInterface from "./VideoInterface";
    import * as panzoom from "panzoom"

    export default {
        name: "VideoItem",
        components: {VideoInterface, VideoLoader},
        props: {
            video: Object,
            frame: Object,
            cameras: Array,
        },
        data() {
            return {
                isLoading: true,
                clickToView: false,
                hasAudio: null,
                isMuted: true,
                isShowControls: false,
                stop: false,
                showError: false,
                isFullScreen: false,
                desktopZoomInstance: null,
                isMobile: null,
                countTap: 0,
                position: {
                    top: 0,
                    bottom: 0
                },
                isIos: false,
                videoSet: {
                    pinchStart: {
                        x: 0,
                        y: 0,
                    },
                    originalSize: {
                        width: 0,
                        height: 0
                    },
                    current: {
                        x: 0,
                        y: 0,
                        z: 1,
                        zooming: false,
                        width: 0,
                        height: 0
                    },
                    last: {
                        x: 0,
                        y: 0,
                        z: 1
                    },
                    type: undefined,
                    delta: {x: undefined, y: undefined}
                }
            }
        },
        computed: {
            getScale() {
                return this.videoSet.current.z
            },
            getVideoStyles() {
                if (this.isFullScreen) {
                    if (this.videoSet.current.z !== 1 && this.isMobile) {
                        return {
                            'transform': 'translate3d(' + this.videoSet.current.x + 'px,' + this.videoSet.current.y + 'px, 0) scale(' + this.videoSet.current.z + ')'
                        }
                    }

                }
                return {'transform': 'translate3d(0,0,0) scale(1)'}
            },
            getWrapperStyles() {
                if (this.isIos && this.isFullScreen) {
                    return {
                        position: 'fixed',
                        top: '0',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                        'z-index': 100,
                        background: '#000'
                    }
                }
                return {}
            },
            isShowShare() {
                if (this.$store.getters.getShareData != null){
                  return false
                }
                if (this.$store.getters.getAccount.type === 0) {
                    return true
                }
                else if(this.$store.getters.getAccount.type === 2) {
                    const childUser = this.$store.getters.getAccount.childUsers.find(item => {
                        return item.id == this.$route.query.account
                    })
                    return childUser && childUser.type === 0
                }
                return false
            },
          isShowArchive(){
              return this.$store.getters.getShareData == null
          },
            getShow() {
                if (this.$store.getters.getTypeView === 'list') {
                    if (!this.position.bottom) {
                        return false
                    } else if (this.position.top >= this.frame.top - 250 && this.position.bottom <= this.frame.bottom + 250) {
                        return true
                    }
                } else {
                    return true
                }
                return false
            },
            getVolume() {
                return this.$store.getters.getVideo.volume
            },
            getVolumeSize() {
                return this.$store.getters.getVideoSize
            }
        },
        methods: {
            changeTap() {
                if (this.$windowWidth < 993 && this.isFullScreen && this.videoSet.current.z > 1) {
                    this.countTap++
                }
            },
            changePan(e) {
                if (this.videoSet.type !== 'pan') {
                    this.videoSet.delta.x = e.deltaX
                    this.videoSet.delta.y = e.deltaY
                }
                this.videoSet.current.x = this.videoSet.last.x + e.deltaX - this.videoSet.delta.x;
                this.videoSet.current.y = this.videoSet.last.y + e.deltaY - this.videoSet.delta.y;
                this.videoSet.type = 'pan'
            },
            endPan() {
                this.videoSet.last.x = this.videoSet.current.x;
                this.videoSet.last.y = this.videoSet.current.y;
                this.videoSet.type = 'panend';
            },
            startPinch(e) {
                this.videoSet.pinchStart = {
                    x: e.center.x,
                    y: e.center.y
                }
                this.pinchZoomOrigin = this.getRelativePosition(this.videoSet.pinchStart, this.videoSet.originalSize, this.videoSet.current.z)
                this.videoSet.type = 'pinchstart'
            },
            endPinch() {
                this.videoSet.last.x = this.videoSet.current.x;
                this.videoSet.last.y = this.videoSet.current.y;
                this.videoSet.last.z = this.videoSet.current.z;
                this.videoSet.type = 'pinchend';
            },
            changePinch(e) {
                if (this.videoSet.last.z * e.scale < 7) {
                    const d = this.scaleFrom(this.pinchZoomOrigin, this.videoSet.last.z, this.videoSet.last.z * e.scale)
                    this.videoSet.current.x = d.x + this.videoSet.last.x + e.deltaX;
                    this.videoSet.current.y = d.y + this.videoSet.last.y + e.deltaY;
                    this.videoSet.current.z = d.z + this.videoSet.last.z;
                    this.videoSet.type = 'pinch';
                    this.videoSet.current.width = this.videoSet.originalSize.width * this.videoSet.current.z
                    this.videoSet.current.height = this.videoSet.originalSize.height * this.videoSet.current.z
                }
            },
            scaleFrom(zoomOrigin, currentScale, newScale) {
                const currentShift = this.getCoordinateShiftDueToScale(this.videoSet.originalSize, currentScale)
                const newShift = this.getCoordinateShiftDueToScale(this.videoSet.originalSize, newScale)
                const zoomDistance = newScale - currentScale

                const shift = {
                    x: currentShift.x - newShift.x,
                    y: currentShift.y - newShift.y,
                }

                const output = {
                    x: zoomOrigin.x * shift.x,
                    y: zoomOrigin.y * shift.y,
                    z: zoomDistance
                }
                return output
            },
            getCoordinateShiftDueToScale(size, scale) {
                const newWidth = scale * size.width;
                const newHeight = scale * size.height;
                const dx = (newWidth - size.width) / 2
                const dy = (newHeight - size.height) / 2
                return {
                    x: dx,
                    y: dy
                }
            },
            getRelativePosition(point, originalSize, scale) {
                const domCoords = this.getCoords()
                const elementX = point.x - domCoords.x
                const elementY = point.y - domCoords.y
                const relativeX = elementX / (originalSize.width * scale / 2) - 1
                const relativeY = elementY / (originalSize.height * scale / 2) - 1

                return {x: relativeX, y: relativeY}
            },
            getCoords() {
                const box = this.$refs.video.getBoundingClientRect()
                const body = document.body;
                const docEl = document.documentElement;

                const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
                const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

                const clientTop = docEl.clientTop || body.clientTop || 0;
                const clientLeft = docEl.clientLeft || body.clientLeft || 0;

                const top = box.top + scrollTop - clientTop;
                const left = box.left + scrollLeft - clientLeft;

                return {x: Math.round(left), y: Math.round(top)};
            },


            onArchive() {
                if (this.$store.getters.getIsPay) {
                    localStorage.setItem('camera', JSON.stringify(this.video))
                    this.$router.push({name: 'Archive'})
                } else {
                    this.showError = true
                }
            },
            loadShow() {
                const video = this.$refs.wrapper.parentNode
                this.position.top = video.offsetTop - 122
                this.position.bottom = this.position.top + video.clientHeight
            },
            generateVideo() {
                const video = this.$refs.video
                this.hlsPlayer = new Hls({
                    liveBackBufferLength: 5,
                    backBufferLength: 5,
                    liveMaxBackBufferLength: 5,
                    maxBufferSize: 30,
                    maxBufferLength: 5,
                    capLevelToPlayerSize: true,
                    liveSyncDurationCount: 1
                })
                video.volume = 0
                if (Hls.isSupported()) {
                    this.hlsPlayer.on('hlsFragChanged', (e, data) => {
                        const range = {
                            startOffset: 0,
                            endOffset: data.frag.startDTS
                        }
                        this.hlsPlayer.trigger('hlsBufferFlushing', range)
                    })
                }
            },
            playVideo() {
                this.isLoading = true
                const video = this.$refs.video
                this.$store.commit('changeVideoCount', true)
                if (this.$store.getters.getTypeView === 'column') {
                    video.volume = 0
                    this.isMuted = true
                    video.muted = true
                }
                if (Hls.isSupported()) {
                    this.hlsPlayer.loadSource(this.video.uri)
                    this.hlsPlayer.attachMedia(video)
                } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = this.video.uri
                }

                if (this.$store.getters.getTypeView === 'column') {
                    video.addEventListener('fullscreenchange', () => {
                        if (!document.fullscreenElement) {
                            this.isMuted = true
                            video.muted = true
                            video.volume = 0
                        }
                    })
                }

                video.addEventListener('waiting', () => {
                    this.isLoading = true
                }, false)

                video.addEventListener('playing', () => {
                    if (typeof video.webkitAudioDecodedByteCount !== 'undefined') {
                        if (video.webkitAudioDecodedByteCount > 0) this.hasAudio = true
                        else this.hasAudio = false
                    } else if (typeof video.mozHasAudio !== 'undefined') {
                        if (video.mozHasAudio) this.hasAudio = true
                        else this.hasAudio = false
                    } else if (typeof video.audioTracks !== 'undefined') {
                        if (video.audioTracks && video.audioTracks.length) this.hasAudio = true
                        else this.hasAudio = false
                    } else {
                        this.hasAudio = false
                    }
                    //this.isLoading = false
                }, false)

                video.addEventListener('loadstart', (e) => {
                    if (e.target.paused) {
                        //this.isLoading = false
                    } else {
                        this.isLoading = true
                    }
                }, false)

                video.addEventListener('error', () => {
                    this.isLoading = false
                }, false)

                video.addEventListener('timeupdate', () => {
                    this.isLoading = false
                }, false)

                video.addEventListener('webkitendfullscreen', () => {
                    setTimeout(() => {
                        video.play()
                    }, 1500)
                }, false)


            },
            toggleMute() {
                this.isMuted = !this.isMuted
                if (!this.isMuted) {
                    this.$store.commit('changeVideo', {volume: this.video.id, volumeSize: 1})
                } else {
                    this.$store.commit('changeVideo', {volume: null, volumeSize: 0})
                }
            },
            async onShare() {
                if (this.$store.getters.getIsPay) {
                    const sh = await this.$store.dispatch('createShare', {cameraId: this.video.id})
                    const formUrl = `https://mobile.eyeinc.ru/share/${sh.token}`
                    const isApp = parseInt(localStorage.getItem('isApp'))
                    // eslint-disable-next-line eqeqeq
                    if (isApp == 1) {
                        try {
                            // eslint-disable-next-line no-undef
                            androidAbility.shareUrl(formUrl)
                        } catch (e) {
                            window.webkit.messageHandlers.doStuffMessageHandler.postMessage(formUrl)
                        }
                    } else {
                        this.$modal.show('ShareLinkModal', {url: formUrl})
                    }
                } else {
                    this.showError = true
                }
            },
            fullScreenToGrid() {
                if (this.$store.getters.getTypeView === 'column' && this.$windowWidth < 993) {
                    this.goToFullSize()
                }
            },
            async goToFullSize() {
                const e = this.$refs.videoWrap
                this.$store.commit("changeFullScreen", 1)
                if (!this.isIos) {
                    if (e.requestFullscreen) {
                        await e.requestFullscreen()
                    } else if (e.mozRequestFullScreen) {
                        await e.mozRequestFullScreen()
                    } else if (e.webkitRequestFullScreen) {
                        await e.webkitRequestFullScreen()
                    }
                }
                this.isFullScreen = true
            },
            showControls(el) {
              console.log(el)
                if (this.$windowWidth > 992) {
                    const classes = [...el.target.classList]
                  const relatedClasses = el.relatedTarget != null ? [...el.relatedTarget.classList] : []
                  this.isShowControls = classes.filter(s => s.includes('video')).length > 0
                      || relatedClasses.filter(s => s.includes('video')).length > 0;
                }
            },
            hideControls(el) {
                if (el.type === 'mouseover' || el.type === 'mouseleave') {
                    this.isShowControls = false
                }
            },
            clickToVideo(e) {
                const el = e.target.classList[0]
                if (this.$windowWidth < 993 && this.$store.getters.getTypeView !== 'column') {
                    if (this.isShowControls && (el === 'video__item' || el === 'video')) {
                            this.isShowControls = false
                    } else {

                        this.isShowControls = true
                    }
                }
            },
            changeFullScreen() {
                if (!document.fullscreenElement) {
                    this.isFullScreen = false
                }
            },
            changePlay(type) {
                return this.stop = type
            },
            changeZoom() {
                // this.countTap = 2
                this.countTap = 2
                if (this.desktopZoomInstance != null){
                    this.desktopZoomInstance.zoomTo(0, 0, 0);
                }
            },
            exitScreen() {
                this.$store.commit("changeFullScreen", 0)
                if (!this.isIos) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen()
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                }

                this.isFullScreen = false

            },
        },
        mounted() {
            console.log(this.$store.getters.getVideoCount)
            this.isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

            this.isIos = this.$refs.video.canPlayType('application/vnd.apple.mpegurl')
            this.isLoading = true
            this.videoSet.originalSize = {
                width: this.$windowWidth,
                height: this.$windowHeight
            }
            this.videoSet.current.width = this.$windowWidth
            this.videoSet.current.height = this.$windowHeight
            this.generateVideo()
            if (this.$store.getters.getTypeView === 'list') {
                this.loadShow()
                window.addEventListener('orientationchange', this.loadShow)
            } else {

                this.playVideo()
            }
        },
        watch: {
            cameras() {
                if (this.$store.getters.getTypeView === 'list') {
                    this.loadShow()
                }
            },
            getScale() {
                if (this.videoSet.current.z <= 1) {
                    this.videoSet.current.z = 1
                    this.videoSet = {
                        pinchStart: {
                            x: 0,
                            y: 0,
                        },
                        originalSize: {
                            width: this.$windowWidth,
                            height: this.$windowHeight
                        },
                        current: {
                            x: 0,
                            y: 0,
                            z: 1,
                            zooming: false,
                            width: this.$windowWidth,
                            height: this.$windowHeight
                        },
                        last: {
                            x: 0,
                            y: 0,
                            z: 1
                        },
                        type: undefined,
                        delta: {x: undefined, y: undefined}
                    }

                }
            },
            countTap() {
                setTimeout(() => {
                    this.countTap = 0
                }, 500)
                if (this.countTap === 2) {
                    this.videoSet.current.z = 1
                }
            },
            isFullScreen() {
                if (this.isFullScreen) {
                    this.isMuted = false
                    this.$refs.video.muted = false
                    this.$refs.video.volume = 1
                    this.$store.commit('changeVideo', {volume: this.video.id, volumeSize: 1})
                    document.addEventListener('fullscreenchange', this.changeFullScreen)

                    if (!this.isMobile) {
                        this.desktopZoomInstance = panzoom(this.$refs.video, {
                            maxZoom: 7,
                            minZoom: 1,
                            bounds: true,
                            boundsPadding: 1
                        })

                        this.desktopZoomInstance.on('zoom', () => {
                            this.videoSet.current.z = this.desktopZoomInstance.getTransform().scale
                            if (this.desktopZoomInstance.getTransform().scale <= 1) {
                                this.$refs.video.style.transform = null
                            }
                        });
                    }
                } else {
                    document.removeEventListener('fullscreenchange', this.changeFullScreen)
                    this.stop = false
                    this.videoSet.current.z = 1
                    if (this.$store.getters.getTypeView === 'column') {
                        this.isMuted = true
                        this.$refs.video.muted = true
                        this.$store.commit('changeVideo', {volume: null, volumeSize: 0})
                    }

                    if (!this.isMobile) {
                        this.desktopZoomInstance.dispose()
                        this.$refs.video.style.transform = null
                    }
                }
            },
            video() {
                if (this.$store.getters.getTypeView === 'default') {
                    this.playVideo()
                    this.$store.commit('changeVideoCount', true)
                    this.$store.commit('changeVideo', {volume: null})
                }
            },
            getShow() {
                if (this.$store.getters.getTypeView === 'list') {
                    if (this.getShow) {
                        this.playVideo()
                    } else {
                        this.$store.commit('changeVideoCount', false)
                        if (this.isIos) {
                            this.$refs.video.pause()
                            this.$refs.video.removeAttribute('src') // empty source
                            this.$refs.video.load()
                        } else {
                            this.$refs.video.pause()
                            this.hlsPlayer.stopLoad()
                            this.hlsPlayer.detachMedia()
                        }
                    }
                } else {
                    this.playVideo()
                }
            },
            getVolume() {
                const video = this.$refs.video
                if (this.type !== 'grid') {
                    if (this.getVolume !== this.video.id) {
                        video.volume = 0
                        this.isMuted = true
                        video.muted = true
                    } else {
                        video.volume = this.$store.getters.getVideo.volumeSize
                        this.isMuted = false
                        video.muted = false
                    }
                }
            },
            getVolumeSize() {
                if (this.getVolumeSize) {
                    this.$refs.video.volume = this.getVolumeSize
                }
            },
            stop(val) {
                const video = this.$refs.video
                if (val) {
                    video.pause()
                } else {
                    video.currentTime = this.hlsPlayer.liveSyncPosition
                    video.play()
                }
            }
        },
        beforeDestroy() {
            this.$store.commit('changeVideoCount', false)
            if (this.hlsPlayer != null && !this.isIos) {
                this.hlsPlayer.detachMedia(this.$refs.video)
                this.hlsPlayer.destroy()
            } else {
                this.$refs.video.pause()
                this.$refs.video.removeAttribute('src') // empty source
                this.$refs.video.load()
            }
            window.removeEventListener('orientationchange', this.loadShow)
        }
    }
</script>

<style scoped lang="scss">
    .video {
        position: absolute;
        top: 0;
        outline: none;
        left: 0;
        right: 0;
        background-color: #000;
        bottom: 0;


        &_bg {
            &::before {
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.224365) 50%, rgba(0, 0, 0, 0.0001) 100%);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 25%;
                z-index: 4;
                transition: all .25s;
                opacity: 1;
            }

            &::after {
                content: '';
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
                bottom: 0;
                height: 25%;
                z-index: 4;
                transition: all .25s;
                opacity: 1;
                left: 0;
                right: 0;
            }
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        &__loader {
            z-index: 4;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
        }

        &__title {
            font-size: 14px;
            font-family: Roboto, "sans-serif";
            font-weight: 100;
            letter-spacing: 0;
            line-height: 16px;
            position: absolute;
            left: 18px;
            top: 19px;
            color: white;
            z-index: 5;
        }

        &__item {
            //transition: all .25s;
            &_grab {
                cursor: grab;
            }

            &_translate {
                transition: all .25s;
            }
        }

        &__text {
            position: absolute;
            top: 10px;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            z-index: 4;
            color: #fff;
            transform: translateX(-50%);
            max-width: 300px;
            width: 100%;
            font-size: 14px;

            &::before {
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.224365) 14.52%, rgba(0, 0, 0, 0.0001) 26.35%, rgba(0, 0, 0, 0.0001) 75.63%, rgba(0, 0, 0, 0.5) 100%);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 4;
                transition: all .25s;
                opacity: 0;
            }

            span {
                max-width: 320px;
                font-family: Roboto, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
            }
        }

        &__info {
            opacity: 1;
            z-index: 5;
            transition: all .5s;

            &:hover {
                opacity: 1;
            }
        }

        &__archive {
            width: 24px;
            height: 15px;
            position: absolute;
            top: 16px;
            right: 22px;
            cursor: pointer;

            &.right {
                right: 60px;
            }

            z-index: 5;

          &:hover {
            filter: brightness(80%);
          }
        }

        &__fullscreen {
            width: 20px;
            z-index: 5;
            height: 18px;
            position: absolute;
            bottom: 20px;
            right: 30px;
            cursor: pointer;

          &:hover {
            filter: brightness(80%);
          }
        }

        &__share {
            position: absolute;
            right: 22px;
            top: 16px;
            width: 19px;
            height: 17px;
            z-index: 5;
          cursor: pointer;

            &:hover {
                filter: brightness(80%);
            }
        }
    }

    .volume-off--image {
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: 20px;
        bottom: 18px;
        left: 20px;
        z-index: 5;
        cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }

    .volume-on--image {
        position: absolute;
        z-index: 5;
        width: 20px;
        height: 20px;
        font-size: 20px;
        bottom: 18px;
        left: 20px;
        cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }

    .no-audio {
        position: absolute;
        bottom: 24px;
        left: 18px;
        z-index: 5;
        font-size: 11px;
        font-weight: 100;
        letter-spacing: 0.34px;
        font-family: Roboto, sans-serif;
        color: #fff;
    }
</style>
